import KYCindex from './BR/KYCindex';
import KYCface from './BR/KYCface';
import KYCback from './BR/KYCback';
import KYChandheld from './BR/KYChandheld';
import KYChead from './BR/KYChead';
import KYCsuccess from './BR/KYCsuccess';
import KYCstatus from './BR/KYCstatus';
import KYCidtype from './BR/KYCidtype';



export default {
  ...KYCindex,
  ...KYCface,
  ...KYCback,
  ...KYChandheld,
  ...KYChead,
  ...KYCsuccess,
  ...KYCstatus,
  ...KYCidtype
};
