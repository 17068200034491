export default {
    'KYCface.choose.header':' 正面の身分証明書の写真をアップロードしてください',
    'KYCface.choose.title':' 顔写真のある身分証明書の写真をアップロードしてください',
    'KYCface.choose.btnLeft':' アルバム',
    'KYCface.choose.btnRight':' 写真を撮る',
    'KYCface.camera.title':' 写真を撮って身分証明書の写真をアップロードしてください',
    'KYCface.camera.describe':' 顔写真のある身分証明書の写真を撮影枠に入れてください',
    'KYCface.confirm.header':' 写真を確認',
    'KYCface.confirm.title':' 画像がクリアで完全であることを確認してください',
    'KYCface.confirm.btnLeft':' 再選択',
    'KYCface.confirm.btnRight':' 確定する',
};