import instance from "@/utils/request";

// 获取资源上传地址
export function getUploadUrl(value:any) {
    return instance.get('/store/v1/sign-upload-object',{
        params:value
    });
}

// 上传图片
export function uploadResource(url:any,value:any) {
    return instance.put(url,value);
}

// 查询消息是否发送成功
export function Login(value:any) {
    return instance.post('/api/v1/user/token',value);
}

// 查询消息是否发送成功
export function LoginV2(value:any) {
    return instance.post('/api/v2/user/token',value);
}

// 用户KYC信息
export function UserKYCInfo() {
    return instance.get('/api/v1/user/info');
}