import KYCindex from './RU/KYCindex';
import KYCface from './RU/KYCface';
import KYCback from './RU/KYCback';
import KYChandheld from './RU/KYChandheld';
import KYChead from './RU/KYChead';
import KYCsuccess from './RU/KYCsuccess';
import KYCstatus from './RU/KYCstatus';
import KYCidtype from './RU/KYCidtype';



export default {
  ...KYCindex,
  ...KYCface,
  ...KYCback,
  ...KYChandheld,
  ...KYChead,
  ...KYCsuccess,
  ...KYCstatus,
  ...KYCidtype
};
