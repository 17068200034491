export default {
    'KYCface.choose.header':'Загрузить фото лицевой стороны документа',
    'KYCface.choose.title':'Пожалуйста, загрузите фото документа с изображением лица',
    'KYCface.choose.btnLeft':'Альбом',
    'KYCface.choose.btnRight':'Сделать фото',
    'KYCface.camera.title':'Сделать фото и загрузить документ с изображением лица',
    'KYCface.camera.describe':'Пожалуйста, поместите документ с изображением лица в фоторамку',
    'KYCface.confirm.header':'Подтвердите фото',
    'KYCface.confirm.title':'Пожалуйста, убедитесь, что фото ясное и полное',
    'KYCface.confirm.btnLeft':'Выбрать заново',
    'KYCface.confirm.btnRight':'Подтвердить',
};