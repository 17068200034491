export default {
    'KYCback.choose.header': 'Upload the back of the ID photo',
    'KYCback.choose.title': 'Please upload the back of your ID photo',
    'KYCback.choose.btnLeft': 'photo album',
    'KYCback.choose.btnRight': 'Photograph',
    'KYCback.camera.title': 'Take a photo and upload the back of the ID photo',
    'KYCback.camera.describe': 'Please put a photo of the back of your ID into the photo frame',
    'KYCback.confirm.header': 'confirm photo',
    'KYCback.confirm.title': 'Make sure the information is clearly seen, without blurring or sedimentation',
    'KYCback.confirm.btnLeft': 'reselect',
    'KYCback.confirm.btnRight': 'determine',
};