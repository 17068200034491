export default {
    'KYCface.choose.header': 'Upload the front of the ID photo',
    'KYCface.choose.title': 'Please upload a passport photo with a portrait face',
    'KYCface.choose.btnLeft': 'photo album',
    'KYCface.choose.btnRight': 'take pictures',
    'KYCface.camera.title': 'Take photos and upload ID photos',
    'KYCface.camera.describe': 'Please put the ID photo with portrait face into the photo frame',
    'KYCface.confirm.header': 'confirm photo',
    'KYCface.confirm.title': 'Make sure the information is clearly seen, without blurring or sedimentation',
    'KYCface.confirm.btnLeft': 'reselect',
    'KYCface.confirm.btnRight': 'determine',
};