import KYCindex from "./US/KYCindex";
import KYCface from "./US/KYCface";
import KYCback from "./US/KYCback";
import KYChandheld from "./US/KYChandheld";
import KYChead from "./US/KYChead";
import KYCsuccess from "./US/KYCsuccess";
import KYCstatus from "./US/KYCstatus";
import KYCidtype from "./US/KYCidtype";



export default {
  ...KYCindex,
  ...KYCface,
  ...KYCback,
  ...KYChandheld,
  ...KYChead,
  ...KYCsuccess,
  ...KYCstatus,
  ...KYCidtype
};
