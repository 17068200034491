export default {
    'KYCface.choose.header':'Carregar a frente da foto de identificação',
    'KYCface.choose.title': 'Por favor, envie uma foto de passaporte com um rosto de retrato',
    'KYCface.choose.btnLeft': 'álbum de foto',
    'KYCface.choose.btnRight': 'tirar fotos',
    'KYCface.camera.title': 'Tire fotos e faça upload de fotos de identificação',
    'KYCface.camera.describe': 'Por favor, coloque a foto de identificação com rosto de retrato na moldura',
    'KYCface.confirm.header': 'confirmar foto',
    'KYCface.confirm.title': 'Certifique-se de que as informações sejam vistas com clareza, sem borrões ou sedimentações',
    'KYCface.confirm.btnLeft': 'selecione novamente',
    'KYCface.confirm.btnRight': 'determinar',
};