export default {
    'KYChand.choose.header': '上传手持证件照',
    'KYChand.choose.title': '确保你的脸很清楚和完全在框架里面',
    'KYChand.choose.btnLeft': '相册',
    'KYChand.choose.btnRight': '拍照',
    'KYChand.camera.title': '手持证件照',
    'KYChand.camera.describe': '请手持证件照,将证件照和脸放入照相框内',
    'KYChand.confirm.header': '确认照片',
    'KYChand.confirm.title': '请确认图片清晰完整',
    'KYChand.confirm.btnLeft': '重新选择',
    'KYChand.confirm.btnRight': '确定',
};