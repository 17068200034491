export default {
    'KYC.loading':'carregando',
    'KYC.noSupport': 'Temporariamente não suporta o tipo de imagem',
    'KYC.clarity': 'A imagem não está nítida',
    'KYC.yes': 'determinar',
    'KYC.no': 'cancelar',
    'KYC.imgBig': 'O volume da imagem é grande demais',
    'KYC.imageRule': 'Limite de tamanho da imagem 5M',
    'KYC.unauthorized': 'Credenciais de usuário ilegais',
    'KYCindex.header': 'Envio de informações KYC',
    'KYCindex.select.country': 'Selecione o pais',
    'KYCindex.accountInfo.title': 'Informação da conta',
    'KYCindex.accountInfo.subtitle': 'Nome de usuário',
    'KYCindex.userId.title': 'ID do usuário',
    'KYCindex.certInfo.title': 'Informações do certificado',
    'KYCindex.certInfo.subtitle': 'Nome legal',
    'KYCindex.selectId.title': 'tipo de identificação',
    'KYCindex.selectId.subtitle': 'Número de identificação',
    'KYCindex.selectId.pikertitle': 'Selecione o tipo de ID',
    'KYCindex.next': 'Próxima Etapa',
    'KYCsubmit.next': 'Enviar',
    'KYCsubmit.face.title': 'Foto da frente do cartão de identificação',
    'KYCsubmit.back.title': 'Verso da foto do passaporte',
    'KYCsubmit.handheld.title': 'Segurando foto de identificação',
    'KYCsubmit.selfie.title': 'foto retrato',
};