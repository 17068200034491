export default {
    'KYCstatust.header': 'KYC認証',
    'KYCstatust.head': '審査結果',
    'KYCstatust.username': 'ユーザー名',
    'KYCstatust.userid': 'ユーザーID',
    'KYCstatust.date': '提出日',
    'KYCstatust.title.noSubmit': '未提出',
    'KYCstatust.title.audit': '審査中',
    'KYCstatust.title.approved': '審査に合格しました',
    'KYCstatust.title.noAudit': '審査に合格しませんでした',
    'KYCstatust.btn.toSubmit': '提出を始める',
    'KYCstatust.btn.wait': 'お待ちください',
    'KYCstatust.btn.through': '合格',
    'KYCstatust.btn.toResubmit': '再提出する',
    'KYCstatust.link': 'カスタマーサポートに連絡する',
};