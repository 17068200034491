export default {
    'KYChand.choose.header':'Carregue uma foto com o passaporte em mãos',
    'KYChand.choose.title': 'Certifique-se de que seu rosto está bem definido e totalmente enquadrado',
    'KYChand.choose.btnLeft': 'álbum de foto',
    'KYChand.choose.btnRight': 'tirar fotos',
    'KYChand.camera.title': 'Segurando foto de identificação',
    'KYChand.camera.describe': 'Por favor, segure sua foto de identificação e coloque sua foto de identidade e rosto na moldura',
    'KYChand.confirm.header': 'confirmar foto',
    'KYChand.confirm.title': 'Certifique-se de que o rosto esteja claramente visível e no enquadramento adequado',
    'KYChand.confirm.btnLeft': 'selecione novamente',
    'KYChand.confirm.btnRight': 'determinar',
};