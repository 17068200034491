export default {
    'KYChand.choose.header': 'Tải lên ảnh ID cầm tay',
    'KYChand.choose.title': 'Đảm bảo khuôn mặt của bạn rõ ràng và hoàn toàn trong khung hình',
    'KYChand.choose.btnLeft': 'album ảnh',
    'KYChand.choose.btnRight': 'ảnh chụp',
    'KYChand.camera.title': 'ảnh ID trong tay',
    'KYChand.camera.describe': 'Vui lòng giữ ảnh ID của bạn và đặt ảnh ID và khuôn mặt của bạn vào khung ảnh',
    'KYChand.confirm.header': 'xác nhận ảnh',
    'KYChand.confirm.title': 'Đảm bảo khuôn mặt có thể nhìn thấy rõ ràng, trong khung phù hợp',
    'KYChand.confirm.btnLeft': 'chọn lại',
    'KYChand.confirm.btnRight': 'Xác nhận',
};