export default {
    'KYChead.choose.header':'อัพโหลดรูปถ่ายเซลฟี่',
    'KYChead.choose.title':'กรุณาตรวจสอบให้แน่ใจว่าหน้าของคุณชัดเจนและอยู่ภายในกรอบ',
    'KYChead.choose.btnLeft':'อัลบั้มภาพ',
    'KYChead.choose.btnRight':'ถ่ายภาพ',
    'KYChead.camera.title':'ถ่ายรูปถือเอกสาร',
    'KYChead.camera.describe':'กรุณาถือเอกสารและวางหน้าในกรอบถ่ายภาพ',
    'KYChead.confirm.header':'ยืนยันรูปภาพ',
    'KYChead.confirm.title':'กรุณาตรวจสอบความคมชัดและความสมบูรณ์ของรูปภาพ',
    'KYChead.confirm.btnLeft':'เลือกใหม่',
    'KYChead.confirm.btnRight':'ยืนยัน',
};