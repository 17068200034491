export default {
    'KYCback.choose.header':' อัปโหลดด้านหลังของรูปถ่ายบัตรประชาชน',
    'KYCback.choose.title':' กรุณาอัปโหลดด้านหลังของรูปถ่ายบัตรประชาชน',
    'KYCback.choose.btnLeft':' อัลบั้มรูป',
    'KYCback.choose.btnRight':' ถ่ายรูป',
    'KYCback.camera.title':' ถ่ายรูปและอัปโหลดด้านหลังของรูปถ่ายบัตรประชาชน',
    'KYCback.camera.describe':' กรุณาใส่ด้านหลังของบัตรประชาชนลงในกรอบถ่ายรูป',
    'KYCback.confirm.header':' ยืนยันรูปภาพ',
    'KYCback.confirm.title':' กรุณายืนยันว่ารูปภาพชัดเจนและครบถ้วน',
    'KYCback.confirm.btnLeft':' เลือกใหม่',
    'KYCback.confirm.btnRight':' ยืนยัน',
};