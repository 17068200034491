export default {
    'KYChead.choose.header':'自撮り写真をアップロードする',
    'KYChead.choose.title':'顔が明瞭で、フレーム内に完全に収まっていることを確認してください',
    'KYChead.choose.btnLeft':'アルバム',
    'KYChead.choose.btnRight':'写真を撮る',
    'KYChead.camera.title':'手持ちの身分証明書写真',
    'KYChead.camera.describe':'身分証明書の写真を持って、身分証明書の写真と顔を撮影フレームに入れてください',
    'KYChead.confirm.header':'写真を確認する',
    'KYChead.confirm.title':'写真がはっきりと完全であることを確認してください',
    'KYChead.confirm.btnLeft':'再選択',
    'KYChead.confirm.btnRight':'確定する',
};