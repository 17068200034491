export default {
    'KYC.loading': 'Đang tải',
    'KYC.noSupport': 'Loại hình ảnh này chưa được hỗ trợ',
    'KYC.clarity': 'hình ảnh không rõ ràng',
    'KYC.yes': 'Xác định',
    'KYC.no': 'Hủy bỏ',
    'KYC.imgBig': 'Hình ảnh quá lớn',
    'KYC.imageRule': 'Giới hạn kích thước hình ảnh 5M',
    'KYC.unauthorized': 'Thông tin người dùng bất hợp pháp',
    'KYCindex.header': 'Gửi thông tin KYC',
    'KYCindex.select.country': 'chọn quốc gia',
    'KYCindex.accountInfo.title': 'Thông tin tài khoản',
    'KYCindex.accountInfo.subtitle': 'Tên tài khoản',
    'KYCindex.userId.title': 'ID người dùng',
    'KYCindex.certInfo.title': 'Thông tin danh tính',
    'KYCindex.certInfo.subtitle': 'Tên hợp pháp',
    'KYCindex.selectId.title': 'Loại ID',
    'KYCindex.selectId.subtitle': 'Số giấy phép',
    'KYCindex.selectId.pikertitle': 'Chọn loại ID',
    'KYCindex.next': 'Bước tiếp theo',
    'KYCsubmit.next': 'Gửi',
    'KYCsubmit.face.title': 'Ảnh mặt trước CMND',
    'KYCsubmit.back.title': 'Ảnh mặt sau CMND',
    'KYCsubmit.handheld.title': 'Ảnh ID trong tay',
    'KYCsubmit.selfie.title': 'Ảnh chụp chân dung',
};