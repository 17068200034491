export default {
    'KYCback.choose.header':'Загрузить фото обратной стороны документа',
    'KYCback.choose.title':'Пожалуйста, загрузите фото обратной стороны документа',
    'KYCback.choose.btnLeft':'Альбом',
    'KYCback.choose.btnRight':'Сделать фото',
    'KYCback.camera.title':'Сделать фото и загрузить обратную сторону документа',
    'KYCback.camera.describe':'Пожалуйста, поместите обратную сторону документа в фоторамку',
    'KYCback.confirm.header':'Подтвердите фото',
    'KYCback.confirm.title':'Пожалуйста, убедитесь, что фото ясное и полное',
    'KYCback.confirm.btnLeft':'Выбрать заново',
    'KYCback.confirm.btnRight':'Подтвердить',
};