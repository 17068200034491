export default {
    'KYCback.choose.header': 'Tải lên mặt sau của ảnh ID',
    'KYCback.choose.title': 'Vui lòng tải lên mặt sau ảnh ID của bạn',
    'KYCback.choose.btnLeft': 'album ảnh',
    'KYCback.choose.btnRight': 'ảnh chụp',
    'KYCback.camera.title': 'Chụp ảnh và tải lên mặt sau của ảnh ID',
    'KYCback.camera.describe': 'Vui lòng dán ảnh mặt sau giấy tờ tùy thân của bạn vào khung ảnh',
    'KYCback.confirm.header': 'xác nhận ảnh',
    'KYCback.confirm.title': 'Đảm bảo nhìn rõ thông tin, không bị nhòe hay lắng cặn',
    'KYCback.confirm.btnLeft': 'chọn lại',
    'KYCback.confirm.btnRight': 'Xác nhận',
};