export default {
    'KYCback.choose.header':'Carregue o verso da foto de identificação',
    'KYCback.choose.title': 'Por favor, carregue o verso da sua foto de identidade',
    'KYCback.choose.btnLeft': 'álbum de foto',
    'KYCback.choose.btnRight': 'Fotografia',
    'KYCback.camera.title': 'Tire uma foto e carregue o verso da foto de identificação',
    'KYCback.camera.describe': 'Coloque uma foto do verso do seu documento de identidade no porta-retratos',
    'KYCback.confirm.header': 'confirmar foto',
    'KYCback.confirm.title': 'Certifique-se de que as informações sejam vistas com clareza, sem borrões ou sedimentações',
    'KYCback.confirm.btnLeft': 'selecione novamente',
    'KYCback.confirm.btnRight': 'determinar',
};