export default {
    'KYC.loading': 'loading',
    'KYC.noSupport': 'Temporarily does not support the image type',
    'KYC.clarity': 'Picture is not clear',
    'KYC.yes': 'determine',
    'KYC.no': 'cancel',
    'KYC.imgBig': 'Image volume too large',
    'KYC.imageRule': 'Image size limit 5M',
    'KYC.unauthorized': 'Illegal user credentials',
    'KYCindex.header': 'KYC information submission',
    'KYCindex.select.country': 'Select country',
    'KYCindex.accountInfo.title': 'Account information',
    'KYCindex.accountInfo.subtitle': 'Username',
    'KYCindex.userId.title': 'User ID',
    'KYCindex.certInfo.title': 'Certificate information',
    'KYCindex.certInfo.subtitle': 'Legal name',
    'KYCindex.selectId.title': 'ID type',
    'KYCindex.selectId.subtitle': 'Identity number',
    'KYCindex.selectId.pikertitle': 'Select ID type',
    'KYCindex.next': 'Next step',
    'KYCsubmit.next': 'Submit',
    'KYCsubmit.face.title': 'Front side photo of ID card',
    'KYCsubmit.back.title': 'The back of the passport photo',
    'KYCsubmit.handheld.title': 'Holding ID photo',
    'KYCsubmit.selfie.title': 'portrait photo',
};