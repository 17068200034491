export default {
    'KYChead.choose.header':'carregar uma selfie',
    'KYChead.choose.title': 'Certifique-se de que seu rosto está bem definido e totalmente enquadrado',
    'KYChead.choose.btnLeft': 'álbum de foto',
    'KYChead.choose.btnRight': 'tirar fotos',
    'KYChead.camera.title': 'Segurando foto de identificação',
    'KYChead.camera.describe': 'Por favor, segure sua foto de identificação e coloque sua foto de identidade e rosto na moldura',
    'KYChead.confirm.header': 'confirmar foto',
    'KYChead.confirm.title': 'Certifique-se de que o rosto esteja claramente visível e no enquadramento adequado',
    'KYChead.confirm.btnLeft': 'selecione novamente',
    'KYChead.confirm.btnRight': 'determinar',
};