export default {
    'KYChand.choose.header': 'อัพโหลดรูปถ่ายถือเอกสาร',
    'KYChand.choose.title': 'ตรวจสอบให้แน่ใจว่าหน้าของคุณชัดเจนและอยู่ภายในเฟรมอย่างเต็มที่',
    'KYChand.choose.btnLeft': 'อัลบั้มรูป',
    'KYChand.choose.btnRight': 'ถ่ายรูป',
    'KYChand.camera.title': 'ถ่ายรูปถือเอกสาร',
    'KYChand.camera.describe': 'กรุณาถือเอกสารและใบหน้าของคุณใส่ในกรอบถ่ายภาพ',
    'KYChand.confirm.header': 'ยืนยันรูปภาพ',
    'KYChand.confirm.title': 'โปรดตรวจสอบให้แน่ใจว่าภาพชัดเจนและครบถ้วน',
    'KYChand.confirm.btnLeft': 'เลือกใหม่',
    'KYChand.confirm.btnRight': 'ตกลง',
};