export default {
    'KYCstatust.header': 'KYC certification',
    'KYCstatust.head': 'Audit results',
    'KYCstatust.username': 'username',
    'KYCstatust.userid': 'User ID',
    'KYCstatust.date': 'submission date',
    'KYCstatust.title.noSubmit': 'unsubmitted',
    'KYCstatust.title.audit': 'under review',
    'KYCstatust.title.approved': 'Reviewed',
    'KYCstatust.title.noAudit': 'failed review',
    'KYCstatust.btn.toSubmit': 'start submitting',
    'KYCstatust.btn.wait': 'Please wait',
    'KYCstatust.btn.through': 'have passed',
    'KYCstatust.btn.toResubmit': 'Resubmit',
    'KYCstatust.link': 'Contact Customer Service',
};