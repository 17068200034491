import KYCindex from './VN/KYCindex';
import KYCface from './VN/KYCface';
import KYCback from './VN/KYCback';
import KYChandheld from './VN/KYChandheld';
import KYChead from './VN/KYChead';
import KYCsuccess from './VN/KYCsuccess';
import KYCstatus from './VN/KYCstatus';
import KYCidtype from './VN/KYCidtype';



export default {
  ...KYCindex,
  ...KYCface,
  ...KYCback,
  ...KYChandheld,
  ...KYChead,
  ...KYCsuccess,
  ...KYCstatus,
  ...KYCidtype
};
