import KYCindex from './JP/KYCindex';
import KYCface from './JP/KYCface';
import KYCback from './JP/KYCback';
import KYChandheld from './JP/KYChandheld';
import KYChead from './JP/KYChead';
import KYCsuccess from './JP/KYCsuccess';
import KYCstatus from './JP/KYCstatus';
import KYCidtype from './JP/KYCidtype';



export default {
  ...KYCindex,
  ...KYCface,
  ...KYCback,
  ...KYChandheld,
  ...KYChead,
  ...KYCsuccess,
  ...KYCstatus,
  ...KYCidtype
};
