export default {
    'KYCstatust.header':'KYC-проверка',
    'KYCstatust.head':'Результаты проверки',
    'KYCstatust.username':'Имя пользователя',
    'KYCstatust.userid':'ID пользователя',
    'KYCstatust.date':'Дата отправки',
    'KYCstatust.title.noSubmit':'Не отправлено',
    'KYCstatust.title.audit':'В процессе проверки',
    'KYCstatust.title.approved':'Проверка пройдена',
    'KYCstatust.title.noAudit':'Проверка не пройдена',
    'KYCstatust.btn.toSubmit':'Начать отправку',
    'KYCstatust.btn.wait':'Пожалуйста, подождите',
    'KYCstatust.btn.through':'Уже пройдено',
    'KYCstatust.btn.toResubmit':'Отправить еще раз',
    'KYCstatust.link':'Связаться с обслуживанием клиентов',
};