export default {
    'KYC.loading': '加载中',
    'KYC.noSupport': '暂不支持该图片类型',
    'KYC.clarity': '图片不清晰',
    'KYC.yes': '确定',
    'KYC.no': '取消',
    'KYC.imgBig': '图片体积过大',
    'KYC.imageRule': '图片大小限制5M',
    'KYC.unauthorized': '非法用户凭证',
    'KYCindex.header': 'KYC信息提交',
    'KYCindex.select.country': '选择国家',
    'KYCindex.accountInfo.title': '账号信息',
    'KYCindex.accountInfo.subtitle': '用户名',
    'KYCindex.userId.title': '用户ID',
    'KYCindex.certInfo.title': '证件信息',
    'KYCindex.certInfo.subtitle': '法定姓名',
    'KYCindex.selectId.title': 'ID类型',
    'KYCindex.selectId.subtitle': '证件号',
    'KYCindex.selectId.pikertitle': '选择ID类型',
    'KYCindex.next': '下一步',
    'KYCsubmit.next': '提交',
    'KYCsubmit.face.title': '证件照正面',
    'KYCsubmit.back.title': '证件照背面',
    'KYCsubmit.handheld.title': '手持证件照',
    'KYCsubmit.selfie.title': '人像照',
};