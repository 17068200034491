export default {
    'KYChand.choose.header': 'Upload a photo with your passport in hand',
    'KYChand.choose.title': 'Make sure your face is well defined and fully in frame',
    'KYChand.choose.btnLeft': 'photo album',
    'KYChand.choose.btnRight': 'take pictures',
    'KYChand.camera.title': 'Holding ID photo',
    'KYChand.camera.describe': 'Please hold your ID photo and put your ID photo and face into the photo frame',
    'KYChand.confirm.header': 'confirm photo',
    'KYChand.confirm.title': 'Make sure the face is clearly visible and in the proper frame',
    'KYChand.confirm.btnLeft': 'reselect',
    'KYChand.confirm.btnRight': 'determine',
};