export default {
    'KYCstatust.header': 'การรับรองตัวตน KYC',
    'KYCstatust.head': 'ผลการตรวจสอบ',
    'KYCstatust.username': 'ชื่อผู้ใช้',
    'KYCstatust.userid': 'รหัสผู้ใช้',
    'KYCstatust.date': 'วันที่ส่ง',
    'KYCstatust.title.noSubmit': 'ยังไม่ได้ส่ง',
    'KYCstatust.title.audit': 'กำลังตรวจสอบ',
    'KYCstatust.title.approved': 'ตรวจสอบผ่าน',
    'KYCstatust.title.noAudit': 'ตรวจสอบไม่ผ่าน',
    'KYCstatust.btn.toSubmit': 'เริ่มการส่ง',
    'KYCstatust.btn.wait': 'โปรดรอ',
    'KYCstatust.btn.through': 'ผ่านแล้ว',
    'KYCstatust.btn.toResubmit': 'ส่งอีกครั้ง',
    'KYCstatust.link': 'ติดต่อฝ่ายบริการลูกค้า',
};