export default {
    'KYCstatust.header': 'Chứng nhận KYC',
    'KYCstatust.head': 'Kết quả xét duyệt',
    'KYCstatust.username': 'Tên người dùng',
    'KYCstatust.userid': 'ID người dùng',
    'KYCstatust.date': 'ngày nộp hồ sơ',
    'KYCstatust.title.noSubmit': 'chưa gửi',
    'KYCstatust.title.audit': 'đang xét duyệt',
    'KYCstatust.title.approved': 'Xét duyệt thông qua',
    'KYCstatust.title.noAudit': 'không được phê duyệt',
    'KYCstatust.btn.toSubmit': 'bắt đầu gửi',
    'KYCstatust.btn.wait': 'Vui lòng chờ ',
    'KYCstatust.btn.through': 'Đã thông qua',
    'KYCstatust.btn.toResubmit': 'gửi lại',
    'KYCstatust.link': 'liên hệ với CSKH',
};