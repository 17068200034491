export default {
    'KYC.loading': 'กำลังโหลด',
    'KYC.noSupport': 'ไม่รองรับประเภทรูปภาพนี้ชั่วคราว',
    'KYC.clarity': 'รูปภาพไม่ชัดเจน',
    'KYC.yes': 'ตกลง',
    'KYC.no': 'ยกเลิก',
    'KYC.imgBig': 'ปริมาณภาพมากเกินไป',
    'KYC.imageRule': 'ขีดจำกัดขนาดภาพ 5M',
    'KYC.unauthorized': 'ใบรับรองผู้ใช้ที่ไม่ถูกต้อง',
    'KYCindex.header': 'ส่งข้อมูล KYC',
    'KYCindex.select.country': 'เลือกประเทศ',
    'KYCindex.accountInfo.title': 'ข้อมูลบัญชีผู้ใช้',
    'KYCindex.accountInfo.subtitle': 'ชื่อผู้ใช้',
    'KYCindex.userId.title': 'รหัสผู้ใช้',
    'KYCindex.certInfo.title': 'ข้อมูลบัตรประชาชน',
    'KYCindex.certInfo.subtitle': 'ชื่อตามกฎหมาย',
    'KYCindex.selectId.title': 'ประเภท ID',
    'KYCindex.selectId.subtitle': 'หมายเลขบัตรประชาชน',
    'KYCindex.selectId.pikertitle': 'เลือกประเภท ID',
    'KYCindex.next': 'ต่อไป',
    'KYCsubmit.next': 'ส่ง',
    'KYCsubmit.face.title': 'รูปหน้าบัตรประชาชน',
    'KYCsubmit.back.title': 'รูปหลังบัตรประชาชน',
    'KYCsubmit.handheld.title': 'ถือบัตรประชาชน',
    'KYCsubmit.selfie.title': 'รูปถ่ายหน้า',
};