import KYCindex from './CN/KYCindex';
import KYCface from './CN/KYCface';
import KYCback from './CN/KYCback';
import KYChandheld from './CN/KYChandheld';
import KYChead from './CN/KYChead';
import KYCsuccess from './CN/KYCsuccess';
import KYCstatus from './CN/KYCstatus';
import KYCidtype from './CN/KYCidtype';



export default {
  ...KYCindex,
  ...KYCface,
  ...KYCback,
  ...KYChandheld,
  ...KYChead,
  ...KYCsuccess,
  ...KYCstatus,
  ...KYCidtype
};
