export default {
    'KYCstatust.header': 'Verifikasi KYC',
    'KYCstatust.head': 'Hasil audit',
    'KYCstatust.username': 'Nama pengguna',
    'KYCstatust.userid': 'ID pengguna',
    'KYCstatust.date': 'Tanggal pengajuan',
    'KYCstatust.title.noSubmit': 'Belum dikirimkan',
    'KYCstatust.title.audit': 'Dalam proses audit',
    'KYCstatust.title.approved': 'Audit berhasil',
    'KYCstatust.title.noAudit': 'Audit gagal',
    'KYCstatust.btn.toSubmit': 'Mulai mengirimkan',
    'KYCstatust.btn.wait': 'Silakan tunggu',
    'KYCstatust.btn.through': 'Telah berhasil',
    'KYCstatust.btn.toResubmit': 'Kirim ulang',
    'KYCstatust.link': 'Hubungi layanan pelanggan',
};