export default {
    'KYChead.choose.header': 'tải lên một bức ảnh tự sướng',
    'KYChead.choose.title': 'Đảm bảo khuôn mặt của bạn rõ ràng và hoàn toàn trong khung hình',
    'KYChead.choose.btnLeft': 'album ảnh',
    'KYChead.choose.btnRight': 'ảnh chụp',
    'KYChead.camera.title': 'Tay cầm chứng minh nhân dân photo',
    'KYChead.camera.describe': 'Vui lòng giữ ảnh ID của bạn và đặt ảnh ID và khuôn mặt của bạn vào khung ảnh',
    'KYChead.confirm.header': 'xác nhận ảnh',
    'KYChead.confirm.title': 'Đảm bảo khuôn mặt có thể nhìn thấy rõ ràng, trong khung phù hợp',
    'KYChead.confirm.btnLeft': 'chọn lại',
    'KYChead.confirm.btnRight': 'Xác nhận',
};