export default {
    'KYChand.choose.header':'Загрузить фото с удостоверением личности, которое держится в руке',
    'KYChand.choose.title':'Убедитесь, что ваше лицо четко видно и полностью находится в рамке',
    'KYChand.choose.btnLeft':'Альбом',
    'KYChand.choose.btnRight':'Сделать фото',
    'KYChand.camera.title':'Фото с удостоверением личности, которое держится в руке',
    'KYChand.camera.describe':'Держите удостоверение личности в руках, поместите его и свое лицо в рамку для фотографии',
    'KYChand.confirm.header':'Подтвердить фото',
    'KYChand.confirm.title':'Проверьте, что фото четкое и полное',
    'KYChand.confirm.btnLeft':'Выбрать заново',
    'KYChand.confirm.btnRight':'Подтвердить',
};