import KYCindex from './TH/KYCindex';
import KYCface from './TH/KYCface';
import KYCback from './TH/KYCback';
import KYChandheld from './TH/KYChandheld';
import KYChead from './TH/KYChead';
import KYCsuccess from './TH/KYCsuccess';
import KYCstatus from './TH/KYCstatus';
import KYCidtype from './TH/KYCidtype';



export default {
  ...KYCindex,
  ...KYCface,
  ...KYCback,
  ...KYChandheld,
  ...KYChead,
  ...KYCsuccess,
  ...KYCstatus,
  ...KYCidtype
};
