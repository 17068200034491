export default {
    'KYCface.choose.header':'อัปโหลดด้านหน้าของรูปถ่ายบัตรประชาชน',
    'KYCface.choose.title':' กรุณาอัปโหลดรูปถ่ายบัตรประชาชนที่มีหน้าผู้ถ่าย',
    'KYCface.choose.btnLeft':' อัลบั้มรูป',
    'KYCface.choose.btnRight':' ถ่ายรูป',
    'KYCface.camera.title':' ถ่ายรูปและอัปโหลดรูปถ่ายบัตรประชาชน',
    'KYCface.camera.describe':' กรุณาใส่รูปถ่ายบัตรประชาชนที่มีหน้าผู้ถ่ายลงในกรอบถ่ายรูป',
    'KYCface.confirm.header':' ยืนยันรูปภาพ',
    'KYCface.confirm.title':' กรุณายืนยันว่ารูปภาพชัดเจนและครบถ้วน',
    'KYCface.confirm.btnLeft':' เลือกใหม่',
    'KYCface.confirm.btnRight':' ยืนยัน',
};