export default {
    'KYC.loading': '読み込み中',
    'KYC.noSupport': 'この画像形式は現在サポートされていません',
    'KYC.clarity': '画像がぼやけています',
    'KYC.yes': '確認',
    'KYC.no': 'キャンセル',
    'KYC.imgBig': '画像の体積が大きすぎる',
    'KYC.imageRule': 'ピクチャサイズ制限5 M',
    'KYC.unauthorized': '不正なユーザー証明書',
    'KYCindex.header': 'KYC情報提出',
    'KYCindex.select.country': '国を選択',
    'KYCindex.accountInfo.title': 'アカウント情報',
    'KYCindex.accountInfo.subtitle': 'ユーザー名',
    'KYCindex.userId.title': 'ユーザーID',
    'KYCindex.certInfo.title': '身分証情報',
    'KYCindex.certInfo.subtitle': '法定名',
    'KYCindex.selectId.title': 'IDタイプ',
    'KYCindex.selectId.subtitle': 'ID番号',
    'KYCindex.selectId.pikertitle': 'IDタイプを選択',
    'KYCindex.next': '次へ',
    'KYCsubmit.next': '送信',
    'KYCsubmit.face.title': 'IDカード表面',
    'KYCsubmit.back.title': 'IDカード裏面',
    'KYCsubmit.handheld.title': '手持ちのIDカード写真',
    'KYCsubmit.selfie.title': '顔写真',
};