export default {
    'KYCback.choose.header': '上传证件照背面',
    'KYCback.choose.title': '请上传证件照背面',
    'KYCback.choose.btnLeft': '相册',
    'KYCback.choose.btnRight': '拍照',
    'KYCback.camera.title': '拍照上传证件照背面',
    'KYCback.camera.describe': '请将证件照背面，放入照相框内',
    'KYCback.confirm.header': '确认照片',
    'KYCback.confirm.title': '请确认图片清晰完整',
    'KYCback.confirm.btnLeft': '重新选择',
    'KYCback.confirm.btnRight': '确定',
};