export default {
    'KYCstatust.header': 'KYC认证',
    'KYCstatust.head': '审核结果',
    'KYCstatust.username': '用户名',
    'KYCstatust.userid': '用户ID',
    'KYCstatust.date': '提交日期',
    'KYCstatust.title.noSubmit': '未提交',
    'KYCstatust.title.audit': '审核中',
    'KYCstatust.title.approved': '审核通过',
    'KYCstatust.title.noAudit': '未通过审核',
    'KYCstatust.btn.toSubmit': '开始提交',
    'KYCstatust.btn.wait': '请等待',
    'KYCstatust.btn.through': '已通过',
    'KYCstatust.btn.toResubmit': '重新提交',
    'KYCstatust.link': '联系客服',
};