import KYCindex from './ID/KYCindex';
import KYCface from './ID/KYCface';
import KYCback from './ID/KYCback';
import KYChandheld from './ID/KYChandheld';
import KYChead from './ID/KYChead';
import KYCsuccess from './ID/KYCsuccess';
import KYCstatus from './ID/KYCstatus';
import KYCidtype from './ID/KYCidtype';



export default {
  ...KYCindex,
  ...KYCface,
  ...KYCback,
  ...KYChandheld,
  ...KYChead,
  ...KYCsuccess,
  ...KYCstatus,
  ...KYCidtype
};
