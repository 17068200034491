export default {
    'KYChead.choose.header': 'Unggah foto selfie',
    'KYChead.choose.title': 'Pastikan wajahmu jelas dan sepenuhnya berada di dalam bingkai',
    'KYChead.choose.btnLeft': 'Album',
    'KYChead.choose.btnRight': 'Ambil foto',
    'KYChead.camera.title': 'Foto memegang ID',
    'KYChead.camera.describe': 'Tahan foto ID, letakkan foto ID dan wajahmu dalam bingkai foto',
    'KYChead.confirm.header': 'Konfirmasi foto',
    'KYChead.confirm.title': 'Pastikan foto jelas dan lengkap',
    'KYChead.confirm.btnLeft': 'Pilih ulang',
    'KYChead.confirm.btnRight': 'Tentu',
};