export default {
    'KYC.loading': 'Sedang memuat',
    'KYC.noSupport': 'Jenis gambar tidak didukung saat ini',
    'KYC.clarity': 'Gambar tidak jelas',
    'KYC.yes': 'menentukan',
    'KYC.no': 'Batal',
    'KYC.imgBig': 'Volum gambar terlalu besar',
    'KYC.imageRule': 'Ukuran gambar batas 5M',
    'KYC.unauthorized': 'Kredensial pengguna tidak sah',
    'KYCindex.header': 'Menyerahkan informasi KYC',
    'KYCindex.select.country': 'Pilih negara',
    'KYCindex.accountInfo.title': 'Informasi akun',
    'KYCindex.accountInfo.subtitle': 'Nama pengguna',
    'KYCindex.userId.title': 'ID pengguna',
    'KYCindex.certInfo.title': 'Informasi identitas',
    'KYCindex.certInfo.subtitle': 'Nama legal',
    'KYCindex.selectId.title': 'Jenis ID',
    'KYCindex.selectId.subtitle': 'Nomor ID',
    'KYCindex.selectId.pikertitle': 'Pilih jenis ID',
    'KYCindex.next': 'Langkah berikutnya',
    'KYCsubmit.next': 'Kirim',
    'KYCsubmit.face.title': 'Foto depan ID',
    'KYCsubmit.back.title': 'Foto belakang ID',
    'KYCsubmit.handheld.title': 'Foto memegang ID',
    'KYCsubmit.selfie.title': 'Foto wajah',
};