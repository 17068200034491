import axios from 'axios'; 


// let mode = 'dev';
// if(mode === 'dev'){ // 开发
//     url = 'http://192.168.0.81:8708';
// }else if(mode === 'test'){  // 测试
//     url = 'http://michat-api-sdk.test.ads4ddcc.com';
// }else if(mode === 'pro'){
//     url = 'https://sdk-api.ads1ddcc.com';
// }


var url = process.env.VUE_APP_BASEURL;


let instance = axios.create({
    baseURL:url
})

instance.interceptors.request.use(function (config) {
    if(config.url === '/store/v1/sign-upload-object' || config.method === 'put'){  // 获取资源上传地址 --- 需清空headers
        config.baseURL = process.env.VUE_APP_S3URL;
        config.headers = { };
        // config.baseURL = 'http://192.168.0.81:8621';
        // config.baseURL = 'http://store-api.test.ads4ddcc.com';
        // config.baseURL = 'https://store-api.ads1ddcc.com';
    }else if(config.url === '/api/v1/user/token'){
        config.headers = {  // 登录 --- 需清空Authorization字段
            
        };
    }else{
        config.headers = {
            'Authorization':localStorage.getItem('token') as string,
        }
    }
    
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default instance;