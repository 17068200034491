export default {
    'KYChand.choose.header':'Unggah foto identitas diri yang dipegang',
    'KYChand.choose.title':'Pastikan wajahmu jelas terlihat dan berada di dalam bingkai sepenuhnya',
    'KYChand.choose.btnLeft':'Album',
    'KYChand.choose.btnRight':'Ambil foto',
    'KYChand.camera.title':'Foto identitas diri yang dipegang',
    'KYChand.camera.describe':'Harap pegang foto identitas diri, masukkan foto identitas diri dan wajahmu ke dalam bingkai foto',
    'KYChand.confirm.header':'Konfirmasi foto',
    'KYChand.confirm.title':'Pastikan foto jelas dan lengkap',
    'KYChand.confirm.btnLeft':'Pilih ulang',
    'KYChand.confirm.btnRight':'Tentu',
};