export default {
    'KYChead.choose.header': 'Загрузить селфи',
    'KYChead.choose.title': 'Убедитесь, что ваше лицо четко видно и полностью находится в рамке',
    'KYChead.choose.btnLeft': 'Фотоальбом',
    'KYChead.choose.btnRight': 'Сделать фото',
    'KYChead.camera.title': 'Документ в руках',
    'KYChead.camera.describe': 'Держите документ в руках, поместите его в рамку и сделайте фотографию',
    'KYChead.confirm.header': 'Подтвердите фото',
    'KYChead.confirm.title': 'Пожалуйста, убедитесь, что изображение четкое и полное',
    'KYChead.confirm.btnLeft': 'Выбрать заново',
    'KYChead.confirm.btnRight': 'Ок',
};