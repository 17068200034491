export default {
    'KYC.loading': 'Загрузка',
    'KYC.noSupport': 'Этот тип изображения не поддерживается в настоящее время',
    'KYC.clarity': 'Изображение нечеткое',
    'KYC.yes': 'ОК',
    'KYC.no': 'Отменить',
    'KYC.imgBig': 'Изображение слишком большое',
    'KYC.imageRule': 'Ограничение размера изображения 5M',
    'KYC.unauthorized': 'Недействительный удостоверение пользователя',
    'KYCindex.header': 'Подтверждение KYC',
    'KYCindex.select.country': 'Выберите страну',
    'KYCindex.accountInfo.title': 'Информация об аккаунте',
    'KYCindex.accountInfo.subtitle': 'Имя пользователя',
    'KYCindex.userId.title': 'ID пользователя',
    'KYCindex.certInfo.title': 'Информация о документе',
    'KYCindex.certInfo.subtitle': 'Официальное имя',
    'KYCindex.selectId.title': 'Тип документа',
    'KYCindex.selectId.subtitle': 'Номер документа',
    'KYCindex.selectId.pikertitle': 'Выберите тип документа',
    'KYCindex.next': 'Следующий',
    'KYCsubmit.next': 'Подтвердить',
    'KYCsubmit.face.title': 'Передняя сторона документа',
    'KYCsubmit.back.title': 'Задняя сторона документа',
    'KYCsubmit.handheld.title': 'С собой документ',
    'KYCsubmit.selfie.title': 'Фото лица',
};