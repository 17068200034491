export default {
    'KYCstatust.header':'certificação KYC',
    'KYCstatust.head': 'Resultados da auditoria',
    'KYCstatust.username': 'nome de usuário',
    'KYCstatust.userid': 'ID do usuário',
    'KYCstatust.date': 'data de submissão',
    'KYCstatust.title.noSubmit': 'não enviado',
    'KYCstatust.title.audit': 'sob revisão',
    'KYCstatust.title.approved': 'Revisado',
    'KYCstatust.title.noAudit': 'revisão reprovada',
    'KYCstatust.btn.toSubmit': 'começar a enviar',
    'KYCstatust.btn.wait': 'Por favor, aguarde',
    'KYCstatust.btn.through': 'passou',
    'KYCstatust.btn.toResubmit': 'Reenviar',
    'KYCstatust.link': 'Contactar o Serviço de Apoio ao Cliente',
};