export default {
    'KYCback.choose.header': 'Unggah bagian belakang foto identitas',
    'KYCback.choose.title': 'Silakan unggah bagian belakang foto identitas',
    'KYCback.choose.btnLeft': 'Album',
    'KYCback.choose.btnRight': 'Ambil foto',
    'KYCback.camera.title': 'Ambil foto dan unggah bagian belakang foto identitas',
    'KYCback.camera.describe': 'Silakan masukkan bagian belakang foto identitas ke dalam bingkai foto',
    'KYCback.confirm.header': 'Konfirmasi foto',
    'KYCback.confirm.title': 'Silakan pastikan foto jelas dan lengkap',
    'KYCback.confirm.btnLeft': 'Pilih ulang',
    'KYCback.confirm.btnRight': 'Konfirmasi',
};