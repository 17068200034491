export default {
    'KYCface.choose.header': 'Unggah foto ID depan',
    'KYCface.choose.title': 'Silakan unggah foto ID dengan sisi potret',
    'KYCface.choose.btnLeft': 'Album',
    'KYCface.choose.btnRight': 'Ambil foto',
    'KYCface.camera.title': 'Ambil foto dan unggah foto ID',
    'KYCface.camera.describe': 'Letakkan foto ID dengan sisi potret dalam bingkai foto',
    'KYCface.confirm.header': 'Konfirmasi foto',
    'KYCface.confirm.title': 'Pastikan foto jelas dan lengkap',
    'KYCface.confirm.btnLeft': 'Pilih ulang',
    'KYCface.confirm.btnRight': 'Konfirmasi',
};