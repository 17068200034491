export default {
    'KYCface.choose.header': '上传证件照正面',
    'KYCface.choose.title': '请上传带有人像面的证件照',
    'KYCface.choose.btnLeft': '相册',
    'KYCface.choose.btnRight': '拍照',
    'KYCface.camera.title': '拍照上传证件照',
    'KYCface.camera.describe': '请将带有人像面的证件照，放入照相框内',
    'KYCface.confirm.header': '确认照片',
    'KYCface.confirm.title': '请确认图片清晰完整',
    'KYCface.confirm.btnLeft': '重新选择',
    'KYCface.confirm.btnRight': '确定',
};