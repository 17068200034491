export default {
    'KYChand.choose.header':'手持ちの身分証明書写真をアップロードする',
    'KYChand.choose.title':'顔が明瞭で、フレーム内に完全に収まっていることを確認してください',
    'KYChand.choose.btnLeft':'アルバム',
    'KYChand.choose.btnRight':'写真を撮る',
    'KYChand.camera.title':'手持ちの身分証明書写真',
    'KYChand.camera.describe':'身分証明書の写真を持って、身分証明書の写真と顔を撮影フレームに入れてください',
    'KYChand.confirm.header':'写真を確認する',
    'KYChand.confirm.title':'写真がはっきりと完全であることを確認してください',
    'KYChand.confirm.btnLeft':'再選択',
    'KYChand.confirm.btnRight':'確定する',
};