export default {
    'KYCback.choose.header':' 裏面の身分証明書の写真をアップロードしてください',
    'KYCback.choose.title':' 身分証明書の裏面の写真をアップロードしてください',
    'KYCback.choose.btnLeft':' アルバム',
    'KYCback.choose.btnRight':' 写真を撮る',
    'KYCback.camera.title':' 写真を撮って身分証明書の裏面の写真をアップロードしてください',
    'KYCback.camera.describe':' 身分証明書の裏面の写真を撮影枠に入れてください',
    'KYCback.confirm.header':' 写真を確認',
    'KYCback.confirm.title':' 画像がクリアで完全であることを確認してください',
    'KYCback.confirm.btnLeft':' 再選択',
    'KYCback.confirm.btnRight':' 確定する',
};