export default {
    'KYCface.choose.header': 'Tải lên mặt trước của ảnh chứng minh thư',
    'KYCface.choose.title': 'Vui lòng tải lên ảnh hộ chiếu có khuôn mặt chân dung',
    'KYCface.choose.btnLeft': 'album ảnh',
    'KYCface.choose.btnRight': 'ảnh chụp',
    'KYCface.camera.title': 'Chụp ảnh và tải lên ảnh ID',
    'KYCface.camera.describe': 'Vui lòng đặt ảnh ID có khuôn mặt chân dung vào khung ảnh',
    'KYCface.confirm.header': 'xác nhận ảnh',
    'KYCface.confirm.title': 'Đảm bảo nhìn rõ thông tin, không bị nhòe hay lắng cặn',
    'KYCface.confirm.btnLeft': 'chọn lại',
    'KYCface.confirm.btnRight': 'Xác nhận',
};