export default {
    'KYChead.choose.header': '上传自拍照',
    'KYChead.choose.title': '确保你的脸很清楚和完全在框架里面',
    'KYChead.choose.btnLeft': '相册',
    'KYChead.choose.btnRight': '拍照',
    'KYChead.camera.title': '手持证件照',
    'KYChead.camera.describe': '请手持证件照,将证件照和脸放入照相框内',
    'KYChead.confirm.header': '确认照片',
    'KYChead.confirm.title': '请确认图片清晰完整',
    'KYChead.confirm.btnLeft': '重新选择',
    'KYChead.confirm.btnRight': '确定',
};