import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { i18n } from '@/locale/index';
import { Login,LoginV2 } from '@/api/user';
import { Toast } from 'vant';



const { t } = i18n.global;


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/kycindex',
    name: 'kycindex',
    component: () => import('../views/KYC/index.vue')
  },

  {
    path: '/kycfacechoose',
    name: 'kycfacechoose',
    component: () => import('../views/KYC/KYCface/choosePhotos.vue')
  },
  {
    path: '/kycfacecamera',
    name: 'kycfacecamera',
    component: () => import('../views/KYC/KYCface/camera.vue')
  },
  {
    path: '/kycfaceconfirm',
    name: 'kycfaceconfirm',
    component: () => import('../views/KYC/KYCface/confirm.vue')
  },

  {
    path: '/kycbackchoose',
    name: 'kycbackchoose',
    component: () => import('../views/KYC/KYCback/choosePhotos.vue')
  },
  {
    path: '/kycbackcamera',
    name: 'kycbackcamera',
    component: () => import('../views/KYC/KYCback/camera.vue')
  },
  {
    path: '/kycbackconfirm',
    name: 'kycbackconfirm',
    component: () => import('../views/KYC/KYCback/confirm.vue')
  },

  {
    path: '/kychandchoose',
    name: 'kychandchoose',
    component: () => import('../views/KYC/KYChandheld/choosePhotos.vue')
  },
  {
    path: '/kychandcamera',
    name: 'kychandcamera',
    component: () => import('../views/KYC/KYChandheld/camera.vue')
  },
  {
    path: '/kychandconfirm',
    name: 'kychandconfirm',
    component: () => import('../views/KYC/KYChandheld/confirm.vue')
  },

  {
    path: '/kycheadchoose',
    name: 'kycheadchoose',
    component: () => import('../views/KYC/KYChead/choosePhotos.vue')
  },
  {
    path: '/kycheadcamera',
    name: 'kycheadcamera',
    component: () => import('../views/KYC/KYChead/camera.vue')
  },
  {
    path: '/kycheadconfirm',
    name: 'kycheadconfirm',
    component: () => import('../views/KYC/KYChead/confirm.vue')
  },

  {
    path: '/kycsubmit',
    name: 'kycsubmit',
    component: () => import('../views/KYC/submit.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/KYC/success.vue')
  },
  {
    path: '/kycstatus',
    name: 'kycstatus',
    component: () => import('../views/KYC/status.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/notFound/notfound.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name:'notfound',
    redirect:'/404'
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach(async (to:any, from:any) => {
  // let {user_id , client_id , timestamp , version , sign , username , sign_type , ignore_pic} = to.query;
  // if(ignore_pic){
  //   sessionStorage.setItem('ignore_pic','1');
  // }
  // if(user_id && client_id && timestamp && version && sign && username){
  //   Toast.loading({
  //     duration: 0,
  //     message: t('KYC.loading'),
  //     forbidClick: true,
  //   });
  //   const resData = {
  //     user_id,
  //     client_id,
  //     timestamp,
  //     version,
  //     sign,
  //     username:decodeURIComponent(username),
  //     sign_type:sign_type ? Number(sign_type) : undefined
  //   };
  //   let loginRes = await Login(resData);
  //   localStorage.setItem('token',loginRes.data.token);
  //   Toast.clear();
  //   console.log('Login',loginRes);
  // }

  let {user_id , account_id , client_id , timestamp , version , sign , username , sign_type , ignore_pic , distinct_id} = to.query;
  if(ignore_pic){
    sessionStorage.setItem('ignore_pic','1');
  }
  if(user_id || account_id){
    if(client_id && timestamp && version && sign && username){
      Toast.loading({
        duration: 0,
        message: t('KYC.loading'),
        forbidClick: true,
      });
      const resData1 = {
        user_id,
        client_id,
        timestamp,
        version,
        sign,
        username:decodeURIComponent(username),
        sign_type:sign_type ? Number(sign_type) : undefined
      };
      const resData2 = {
        account_id,
        client_id,
        timestamp,
        version,
        sign,
        username:decodeURIComponent(username),
        sign_type:sign_type ? Number(sign_type) : undefined,
        distinct_id:distinct_id ? distinct_id : undefined
      };
      let loginRes = undefined;
      if(!account_id){
        loginRes = await Login(resData1);
      }else{
        loginRes = await LoginV2(resData2);
      }
      console.log('Login',loginRes);
      localStorage.setItem('token',loginRes.data.token);
      Toast.clear();
    }
  }
})

export default router
