import { getUploadUrl , uploadResource } from '@/api/user';
import { Toast } from 'vant';
import axios from 'axios'; 



// URL地址解析
export function getJsUrl(url:any):any{
	var pos,str,para,parastr; 
	var array:any =[]
	str = location.href; 
	parastr = str.split("?")[1]; 
	var arr = parastr.split("&");
	for (var i=0;i<arr.length;i++){
        array[arr[i].split("=")[0]]=arr[i].split("=")[1];
    }
    return array;
}

// 复制
export function copy(data:any) {
    let OrderNumber = data;
    let newInput = document.createElement("input");
    newInput.value = OrderNumber;
    document.body.appendChild(newInput);
    newInput.select();
    document.execCommand("Copy");
    newInput.remove();
}

// Base64转文件流
export function base64toFile(urlString:any, fileName:any) {
    const dataArr = urlString.split(",");
    const byteString = atob(dataArr[1]);
    const options:any = {
      type: "image/png",
      endings: "native"
    };
    const u8Arr = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      u8Arr[i] = byteString.charCodeAt(i);
    }
    return new File([u8Arr], fileName + ".png", options);//返回文件流
}

// 时间戳转义当地时间
export function onGetLocalTime(date:number){
  var time = new Date(date).toLocaleString();
  return time.slice(0,9);
}

// 获取资源上传地址 正常
// export const upload = async (file:any,supportMsg:any,loadMsg:any) => {
//   let extend = file.name.split('.')[1];
//   let formatArr = ['jpg','jpeg','png','mp4'];
//   if(formatArr.includes(extend) === false){
//       Toast(supportMsg);
//       return;
//   }
//   Toast.loading({
//     duration: 0,
//     message: loadMsg,
//     forbidClick: true,
//   });
//   const info = {
//       project:'saas',
//       prefix:'kyc',
//       extend
//   }
//   const url = await getUploadUrl(info).then(res=>{
//       console.log('获取资源上传地址',res);
//       return res.data.sign_url;
//   })
//   await uploadResource(url,file).then(res=>{
//       console.log('资源上传成功',res);
//   })
//   Toast.clear();
//   return url.split('?')[0].replace('https://mili-osstore-pub-ws.s3.ap-southeast-1.amazonaws.com','https://wscdn.ads1ddcc.com');
// }
 
// 获取资源上传地址 进度条
export const upload = async (file:any,onUploadProgress:any,supportMsg:any,loadMsg:any) => {
  // let extend = file.name.split('.')[1];
  console.log(file.type.split('/')[1]);
  let extend = file.type.split('/')[1];
  let formatArr = ['jpg','jpeg','png','mp4'];
  if(formatArr.includes(extend) === false){
      Toast(supportMsg);
      return;
  }
  Toast.loading({
    duration: 0,
    message: loadMsg,
    forbidClick: true,
  });
  const info = {
      project:'saas',
      prefix:'kyc',
      extend
  }
  const url = await getUploadUrl(info).then(res=>{
      console.log('获取资源上传地址',res);
      return res.data.sign_url;
  })
  await axios({
    method: 'put',
    url: url,
    data:file,
    onUploadProgress
  }).then(res=>{
    console.log('资源上传成功',res);
  })
  Toast.clear();
  return url.split('?')[0].replace('https://mili-osstore-pub-ws.s3.ap-southeast-1.amazonaws.com','https://wscdn.ads1ddcc.com');
}